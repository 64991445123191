<template>
  <main>
    <heroComponent />
    <div class="container marketing my-4">
      <div class="row py-4">
        <div class="col-lg-4">
          <div class="circle-icon">
              <i class="fas fa-paw fa-3x"></i>
          </div>
          <h2 class="py-3">Local Mission</h2>
          <p>
              Invest in Wellsville's future – your donation directly supports seniors from WHS who dream of higher education, building a brighter tomorrow for our local community.
          </p>
        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <div class="circle-icon">
              <i class="fas fa-dollar-sign fa-3x"></i>
          </div>
          <h2 class="py-3">$9,000 Raised</h2>
          <p>
              Thanks to the generosity of our past donors, money raised has already transformed the dreams of local students, and your support continues to make a lasting impact.
          </p>
        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <div class="circle-icon">
              <i class="fas fa-user fa-3x"></i>
          </div>
          <h2 class="py-3">9 Students Awarded</h2>
          <p>
              Annually awarding one deserving senior, our scholarship program looks forward to many more years of empowering students to achieve their dreams through education.
          </p>
        </div><!-- /.col-lg-4 -->
      </div><!-- /.row -->

      <CurrentWinner />

      <donateComponent />

      <div class="row flex-lg-row-reverse">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <img src="@/assets/cathy-salter/1982-memorial.jpg" alt="A slate memorial for Cathy Salter Memorial being displayed at Wellsville High School Class Day" class="img-fluid mx-auto rounded">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 p-4">
          <h2 class="fw-bold">Remembering Our <span class="text-danger">Friend Cathy</span></h2>
          <p class="lead">
            Cathy Patricia Salter, grew up in Wellsville with five siblings and excelled in academics and sports as a co-valedictorian in 1982. Later, she earned a bachelor's degree in history from the University of Cincinnati before working her way up the corporate ladder to Vice President at AON Corporation.
          </p>
          <p class="lead">
            Cathy was a member of the WHS Graduating Class of 1982 
          </p>
        </div>
      </div>

      <div class="featurette-divider"></div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <img src="@/assets/911-memorial/cathys-name.jpg" alt="The South Tower Fountain Memorial in New York City with Cathy's name inscribed in the foreground" class="img-fluid mx-auto rounded">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 p-4">
          <h2 class="fw-bold">Gone But <span class="text-danger">Never Forgotten.</span></h2>
          <p class="lead">The 9/11 Memorial in New York City bears the engraved names of almost 3,000 individuals, each identifiable through the panel on which it is inscribed. A panel address consists of the letter N or S (representing north pool or south pool, respectively) followed by a number ranging from 1 to 76.</p>
          <p class="lead">
            You can find Cathy's name inscribed on panel S-60.
          </p>
        </div>
      </div>

      <div class="featurette-divider"></div>

      <articlesComponent />

    </div><!-- /.container -->
  </main>
</template>

<script>
// @ is an alias to /src
import heroComponent from '@/components/Hero.vue'
import donateComponent from '@/components/Donate.vue'
import articlesComponent from '@/components/Articles.vue'
import CurrentWinner from '@/components/CurrentWinner.vue';

import { useSeoMeta } from '@unhead/vue';

  const t = "The Cathy Salter Memorial Scholarship - Wellsville High School, Ohio"
  const d = "A scholarship founded in 2014, the Cathy Salter Memorial Scholarship was founded by Jamie Hardman.  Cathy was a 1982 graduate of Wellsville High School who perished in the September 11th attacks."

  useSeoMeta({
      title: t,
      description: d,
      ogDescription: d,
      ogTitle: t,
      ogImage: 'https://example.com/image.png',
      twitterCard: 'summary_large_image',
  })

export default {
  name: 'HomeView',
  components: {
    heroComponent,
    donateComponent,
    articlesComponent,
    CurrentWinner
  }
}
</script>

<style scoped>
/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}

.circle-icon{
    height: 100px;
    width: 100px;
    background: maroon;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.text-danger{
  color: maroon !important;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 35rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 40rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */


/* Featurettes------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  /* rtl:remove */
  letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
</style>
