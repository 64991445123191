<template>
    <div class="px-4 py-5 my-5 text-center">
        <h1 class="display-5 fw-bold">Consider Donating Today</h1>
        <div class="col-lg-6 mx-auto">
            <p class="lead mb-4">
                Your donation is greatly appreciated, and will directly impact the life of a Wellsville High School Graduate in furthering their educational and social goals.  All donations are tax deductible through the Wellsville High School Alumni Association's 501-C3 number.
            </p>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <router-link to="/donate" class="btn btn-dark btn-lg px-4 gap-3">Ways to Donate</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'donateComponent'
}
</script>

<style>
</style>