<template>
    <div class="bg-black text-light">
        <div class="container">
            <footer class="row py-5 border-top">
                <div class="col-lg-3 d-flex justify-content-center">
                    <router-link to="/" class="d-flex justify-content-center text-decoration-none">
                        <img class="d-block mx-auto mb-4" src="@/assets/logo/logo-1000-1000-black.png" alt="The Cathy Salter Memorial Wellsville High School Scholarship logo" width="200" height="200">
                    </router-link>
                </div>
                <div class="col-lg-3 py-2 text-center">
                    <p class="h5 pt-4">Navigation</p>
                    <hr class="w-50 mx-auto text-light">
                    <ul class="nav flex-column">
                        <li class="nav-item m-2"><router-link to="/about" class="p-0">About</router-link></li>
                        <li class="nav-item m-2"><router-link to="/donate" class="p-0">Donate</router-link></li>
                        <li class="nav-item m-2"><router-link to="/golf-outing" class="p-0">Golf Outing</router-link></li>
                    </ul>
                </div>
                <div class="col-lg-3 my-2 text-center">
                    <p class="h5 pt-4">Contact Us</p>
                    <hr class="w-50 mx-auto text-light">
                    <ul class="nav flex-column">
                        <li class="nav-item m-2">330-383-1709</li>
                        <li class="nav-item m-2">CathySalterScholarship@Yahoo.com</li>
                        <li class="nav-item m-2">1125 Esther Avenue<br>Wellsville Ohio 43968</li>
                    </ul>
                </div>
                <div class="col-lg-3 my-2 text-center">
                    <p class="h5 pt-4">Follow Us</p>
                    <hr class="w-50 mx-auto text-light">
                    <ul class="nav flex-column">
                        <li class="nav-item m-2"><a href="https://www.facebook.com/p/The-Cathy-Salter-Memorial-Scholarship-100064560344887/" target="_blank" class="text-decoration-none p-0"><i class="fab fa-facebook"></i> Facebook</a></li>
                    </ul>
                </div>
            </footer>
            <div class="row text-center">
                <hr>
                <p class="py-3">Made by <a href="https://www.lc3creative.com" target="_blank" rel="noopener" class="text-decoration-none">LC3 Creative</a></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'footer_component'
}
</script>

<style scoped>
    a, router-link, .nav-link{
        color: white;
        text-decoration: none;
    }
    a:hover, router-link:hover, .nav-link:hover{
        color: rgb(198, 198, 198);
    }
</style>