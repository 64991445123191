// Import Statements
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueHeadMixin, createHead } from '@unhead/vue'

// Application Instance Creation
const app = createApp(App)

const head = createHead()
app.mixin(VueHeadMixin)
app.use(head)

// Use Vue Router
app.use(router)

// Mount the Application
app.mount('#app')
