<template>
    <div>
        <div class="hero"></div>
    </div>
</template>

<script>
export default {
    name: 'heroComponent',
    props:{
        bg_image: String,
    }
}
</script>

<style>
    .hero {
        height: 90vh;
        background: url('@/assets/911-memorial/jason-mccann-black-fade-edit.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 250px;
    }

    @media only screen and (max-width: 1200px) {
        .hero {
            height: 70vh;
        }
    }

    @media only screen and (max-width: 800px) {
        .hero {
            height: 50vh;
        }
    }

    @media only screen and (max-width: 600px) {
        .hero {
            height: 30vh;
        }
    }
</style>
