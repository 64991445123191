<template>
     <div class="row my-5">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <img src="@/assets/award-winners/2024-SamanthaDrysdale.jpg" alt="The 2024 Winner of the Cathy Salter Memorial Scholarship, Samantha Drysdale" class="winner mx-auto rounded">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 p-4">
          <h2 class="fw-bold">Samantha Drysdale<br><span class="text-danger">The 2024 Scholarship Winner</span></h2>
          <p class="lead">We are proud to announce this year’s recipient of the Cathy Salter Memorial Scholarship is Samantha Drysdale. Samantha plans to double major in music and education at Mount Union University this fall and wants to become a band director. Best of luck to Samantha in all her future endeavors.</p>
          <router-link to="/previous-awards" class="btn btn-dark btn-lg px-4 gap-3">Previous Winners</router-link>
        </div>
      </div>
</template>

<script>
export default {
  name: 'CurrentWinner',
  props: {
    image: String,
    name: String,
    year: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .winner{
        display: block;
        margin: auto;
        height: 100%;
        width: 100%;
        max-width: 450px;
        object-fit: cover;
    }
</style>
