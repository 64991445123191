<template>
    <div class="row mb-2">
        <div class="col-md-6">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-danger">WKBN</strong>
                <h3 class="mb-1">Memory of local 9/11 victim lives on through scholarship</h3>
                <div class="mb-1 text-muted">July 4, 2024</div>
                <p class="card-text mb-auto">The legacy of a former Wellsville woman lives on for generations in the form of a scholarship.</p>
                <a href="https://www.wkbn.com/news/local-news/wellsville-news/memory-of-local-9-11-victim-lives-on-through-scholarship/" class="stretched-link" target="_blank" rel="noopener">Continue reading <i class="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-danger">Morning Journal</strong>
                <h3 class="mb-1">9/11: Mother of victim remembers fateful day</h3>
                <div class="mb-1 text-muted">September 11, 2021</div>
                <p class="card-text mb-auto">“In my heart, I knew she died the minute the plane hit the second building,” Eleanor Salter said of her daughter, Cathy, who died during terrorists attack on the Twin Towers</p>
                <a href="https://www.morningjournalnews.com/news/local-news/2021/09/9-11-mother-of-victim-remembers-fateful-day/" class="stretched-link" target="_blank" rel="noopener">Continue reading <i class="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-danger">Sky News</strong>
                <h3 class="mb-1">The lives lost on 9/11</h3>
                <div class="mb-1 text-muted">September 11, 2021</div>
                <p class="card-text mb-auto">2,977 people were killed in the terror attacks on September 11th 2001. Most were in the north and south towers of New York's World Trade Center. Lost - but not forgotten - these are all their names.</p>
                <a href="https://news.sky.com/story/9-11-anniversary-2-977-people-were-killed-on-9-11-these-are-the-names-of-all-those-lives-lost-12402270" class="stretched-link" target="_blank" rel="noopener">Continue reading <i class="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-danger">WYTV</strong>
                <h3 class="mb-1">‘She’s always with me’: Wellsville mother</h3>
                <div class="mb-1 text-muted">September 10, 2021</div>
                <p class="mb-auto">“I don’t have to do anything special to remember, she’s always with me. Always,” Eleanor said. “Those first 15 years were really hard. It just seemed like every time I turned around, there was something that reminded me of her.”</p>
                <a href="https://www.wytv.com/news/local-news/shes-always-with-me-wellsville-mother-remembers-daughter-killed-in-9-11-attacks/" class="stretched-link" target="_blank" rel="noopener">Continue reading <i class="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-danger">The Review Online</strong>
                <h3 class="mb-1">Remembering Cathy</h3>
                <div class="mb-1 text-muted">September 12, 2019</div>
                <p class="mb-auto">Wellsville village officials gathered at the Boy and the Boot to honor Cathy Salter and all victims of 9/11. Salter was co-valedictorian of the Wellsville class of 1982, and she was working in the South tower when it was struck by a plane. </p>
                <a href="https://www.reviewonline.com/news/local-news/2019/09/remembering-cathy/" class="stretched-link" target="_blank" rel="noopener">Continue reading <i class="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-danger">Legacy</strong>
                <h3 class="mb-1">Remembering September 11, 2001</h3>
                <div class="mb-1 text-muted">September 11, 2018</div>
                <p class="mb-auto">It was the most profound single day of mass grief America had seen in generations. Now, twenty years later, the legacy of Sept. 11 is still reverberating through the world. </p>
                <a href="https://www.legacy.com/news/culture-and-history/remembering-september-11-2001/" class="stretched-link" target="_blank" rel="noopener">Continue reading <i class="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'articleComponent'
}
</script>

<style>
.stretched-link{
    color: black;
    margin: 1rem 0;
}
.text-danger{
  color: maroon !important;
}
</style>