<template>
  <headerComponent/>
  <router-view/>
  <footerComponent/>
</template>

<script>
import headerComponent from './components/header-footer/header.vue'
import footerComponent from './components/header-footer/footer.vue'

export default {
  components:{
    headerComponent,
    footerComponent
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=DotGothic16&family=Playfair+Display&display=swap');
h1,h2,h3,h4,h5,h6{
  font-family: 'Playfair Display', serif;
}
.h1,.h2,.h3,.h4,.h5,.h6{
  font-family: 'Playfair Display', serif;
}
p{
  font-family: Arial, Helvetica, sans-serif
}

.bg-danger, .btn-danger{
  background: rgb(95, 1, 1);
  border: 1px solid black;
}
.btn-danger:hover{
  background: rgb(70, 1, 1) !important;
}
.text-danger{
  color: rgb(95, 1, 1) !important;
}
</style>
