<template>
    <div class="bg-danger text-light text-center">
        Come see us at Tag Day in Wellsville - June 29th, 2024
    </div>
    <nav class="navbar navbar-expand-lg navbar-dark text-center bg-black">
        <div class="container">
            <router-link class="navbar-brand" to="/">
                <img src="@/assets/logo/logo-horizontal.png" class="image-header" alt="The Cathy Salter Memorial Wellsville High School Scholarship logo">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 bg-black">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/about">About</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/previous-awards">Awards</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="eventsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Events
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="eventsDropdown">
                            <li><router-link class="dropdown-item" to="/golf-outing">Golf Outing Registration</router-link></li>
                            <!-- <li><hr class="dropdown-divider"></li> -->
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="externalDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            External Links
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="externalDropdown">
                            <li><a class="dropdown-item" href="http://www.wellsville.k12.oh.us/" target="_blank" rel="noopener">Wellsville High School</a></li>
                            <li><a class="dropdown-item" href="https://www.facebook.com/WellsvilleHighSchoolAlumniAssociation/" target="_blank" rel="noopener">Wellsville Alumni</a></li>
                            <li><a class="dropdown-item" href="https://www.911memorial.org/" target="_blank" rel="noopener">9/11 Memorial</a></li>
                            <li><a class="dropdown-item" href="https://www.uc.edu/news/articles/legacy/enews/2006/08/sept-11-memorial-grows-on-ucs-campus.html" target="_blank" rel="noopener">Univ. of Cincinnati News</a></li>
                            <li><a class="dropdown-item" href="https://www.legacy.com/obituaries/name/catherine-salter-obituary?pid=147188&view=guestbook" target="_blank" rel="noopener">Legacy.com</a></li>
                            <li><a class="dropdown-item" href="https://voicescenter.org/living-memorial/victim/catherine-patricia-salter" target="_blank" rel="noopener">Voices Center</a></li>
                            <li><a class="dropdown-item" href="https://saltermemorials.com/whs2001.htm" target="_blank" rel="noopener">Salter Memorials</a></li>
                        </ul>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li> -->
                </ul>
                <div class="d-flex justify-content-center">
                    <router-link to="/donate" class="btn btn-danger brand">Donate</router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'header_component'
}
</script>

<style>
    
    .brand{
        font-family: 'Playfair Display', serif;
    }
    .nav-link{
        color: whitesmoke;
        font-family: 'Playfair Display', serif;
    }
    .image-header{
        height: 125px;
        width: 300px;
    }
    .bg-danger, .btn-danger{
        background: rgb(95, 1, 1) !important;
        border: 1px solid black;
    }
    .text-danger{
        color: rgb(95, 1, 1) !important;
    }
    @media (max-width: 1000px){
        .image-header{
            height: 100%;
            width: 225px;
            
        }
    }
</style>