// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'The Cathy Salter Memorial Scholarship - Wellsville High School, Ohio',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About | The Cathy Salter Memorial Scholarship',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/donate',
    name: 'Donate | The Cathy Salter Memorial Scholarship',
    component: () => import('../views/DonateView.vue')
  },
  {
    path: '/golf-outing',
    name: 'Annual Golf Outing | The Cathy Salter Memorial Scholarship',
    component: () => import('../views/GolfView.vue')
  },
  {
    path: '/previous-awards',
    name: 'Previous Awards | The Cathy Salter Memorial Scholarship',
    component: () => import('../views/AwardedView.vue')
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "ERROR 404 | The Cathy Salter Memorial Scholarship",
    component: () => import('../views/PageNotFound.vue')
  }
]

const router = createRouter({
  // remove # from URLs
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

export default router
